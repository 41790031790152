import { HTTP } from '@/utils/request'
import callRequest from '@/utils/callRequest'
import md5 from 'md5'

// 根据userld查询最后一次登录坐席
export const reqGetLastCnoByUserId = () => {
  const userId = sessionStorage.jc_operatorId
  const url = '/prod-api/customer/public/api/v1/telephone/getLastCnoByUserId?'
  return HTTP(url + 'enterpriseId=' + process.env.VUE_APP_ENTERPRISE_ID + '&userId=' + userId, {}, 'get')
}

// 坐席下线
export const reqCellLogout = cno => {
  console.log(process.env)
  const enterpriseId = process.env.VUE_APP_ENTERPRISE_ID
  const timestamp = Math.round(new Date().getTime() / 1000).toString()
  const sign = md5(enterpriseId + timestamp + process.env.VUE_APP_TOKEN)
  return callRequest.get('agent/logout', {
    params: {
      validateType: '2',
      enterpriseId,
      timestamp,
      sign,
      cno,
      ignoreOffline: 0
    }
  })
}
