import cookies from "vue-cookies";
export const setToken = (data) => {
  // 获取当前日期
  const currentDate = new Date();
  // 设置过期时间为当天的23点59分59秒
  const expireDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    23,
    59,
    59
  );
  cookies.set("token", data, expireDate);
};

export const getToken = () => {
  return cookies.get("token") || null;
};

export const clearToken = () => {
  return cookies.remove("token");
};
