import { HTTP } from '@/utils/request'

// 查询群列表
export const GetGroupList = data =>
  HTTP(`/prod-api/business/inform/groupList`, data, 'get')

// 群发上传图片
export const PostGroupPic = data => HTTP(`/prod-api/emr/upload`, data, 'post')

// 群发消息
export const PostGroupMessage = data =>
  HTTP(`/prod-api/business/inform/send`, data)
