import axios from 'axios'

const request = axios.create({
  baseURL: 'call-api'
})

// 请求拦截器
request.interceptors.request.use(
  async function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// 响应拦截器
request.interceptors.response.use(
  function (response) {
    if (response.data.code === 500) {
      console.log({ response: response.data })
      console.log({
        data: response.config.data,
        url: response.config.url
      })
    }
    return response
  },
  function (error) {
    console.dir(error)
    return Promise.reject(error)
  }
)

export default request
