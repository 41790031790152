import Vue, { h } from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// cookies
import cookies from "vue-cookies";
// 图标
import "@/styles/iconfont.css";
import { getToken } from "./hooks/cookies";
import { GetUserInfo } from "./services/api";
/* v-md-editor 编辑器 start  */
/* 1、v-md-editor 基础引用  */
import VueMarkdownEditor from "@kangc/v-md-editor";
import "@kangc/v-md-editor/lib/style/base-editor.css";
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
import "@kangc/v-md-editor/lib/theme/style/vuepress.css";
import Prism from "prismjs";
VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});

/* 2、v-md-editor 代码块关键字高亮  */
import githubTheme from "@kangc/v-md-editor/lib/theme/github.js";
import "@kangc/v-md-editor/lib/theme/style/github.css";
// 引入所有语言包
import hljs from "highlight.js";
VueMarkdownEditor.use(githubTheme, {
  Hljs: hljs,
});
Vue.use(VueMarkdownEditor);

/* 3、v-md-editor 二次封装  */
import mdEditor from "@/components/vMdEditor";
Vue.component("MdEditor", mdEditor);
/* v-md-editor 编辑器 end  */

Vue.use(ElementUI);
Vue.use(cookies);
Vue.config.productionTip = false;

router.beforeEach(async (to, _, next) => {
  let token = getToken();
  if (!token && to.path !== "/login") {
    next({ path: `/login?redirectUrl=${to.fullPath}` });
  } else {
    if (to.path !== "/login") {
      const { data } = await GetUserInfo();
      sessionStorage.phoneFlag = data.phoneFlag;
      sessionStorage.jc_operatorId = data.userId;
      sessionStorage.ljProviderId = data.ljProviderId;
      sessionStorage.ddUserId = data.ddUserId;
      sessionStorage.jcUserName = data.userName;
      sessionStorage.jc_odos_user = JSON.stringify(data);
      next();
    } else {
      next();
    }
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

const hina = window["hinaDataStatistic"];
hina.init({
  // 配置采集上报地址URL
  serverUrl: "https://higateway.haishuu.com/ha?project=jc001&token=WQLjGRJW", // 配置采集上报地址URL（在线申请），查看如下【[采集上报地址获取] 链接
  showLog: true,
  autoTrackConfig: {
    // 是否开启自动点击采集, true表示开启，自动采集 H_WebClick 事件
    clickAutoTrack: true,
    // 是否开启页面停留采集, true表示开启，自动采集 H_WebStay 事件
    stayAutoTrack: true,
    // 是否开启页面浏览采集，auto 表示开启，singlePage 表示单页面开启，false 关闭，可配合手动开启使用
    // 若页面中有锚点设计，需要将该配置设为 false，否则触发锚点会多触发 H_pageview 事件
    pageviewAutoTrack: "singlePage",
    //  是否开启页面离开采集，true 表示开启，具体配置查看插件集成
    pageLeaveAutoTrack: true,
  },
});
window["hina"] = hina;
