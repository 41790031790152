import { HTTP } from "@/utils/request";

// 测试
// const baseURL = process.env.VUE_APP_API_URL;
// 登录
export const UserLogin = (data) => HTTP(`/prod-api/auth-business/login`, data);

export const AuthLogin = (data) =>
  HTTP(`/prod-api/auth-business/scanCodeLogin`, data);

// 医生列表
export const DoctorList = (data) =>
  HTTP(`/prod-api/business/user/list?title=${data.doctor}`, data, "get");

// 创建患者
export const SavePatient = (data) => HTTP(`/prod-api/business/patient`, data);

// 查询用户信息
export const GetUserInfo = (data) =>
  HTTP(`/prod-api/business/user/queryLoginInfo`, data, "get");

// 查询门店列表
export const GetStoreList = (data) =>
  HTTP(`/prod-api/business/office/list`, data, "get");

// 选择门店
export const LoginOffice = (data) =>
  HTTP(`/prod-api/business/user/updateLoginOffice`, data, "put");

// 用户管理
export const UserList = (data) => {
  let url = "";
  if (data.officeIdGroup) {
    url = `/prod-api/business/user/list?name=${data.name}&pageNum=${data.pageNum}&pageSize=${data.pageSize}&officeIdGroup=${data.officeIdGroup}`;
  } else {
    url = `/prod-api/business/user/list?name=${data.name}&pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
  }
  return HTTP(url, data, "get");
};

// 删除用户
export const DeleteUser = (data) =>
  HTTP(`/prod-api/business/user/${data.id}`, data, "delete");

// 修改用户
export const EditUser = (data) => HTTP(`/prod-api/business/user`, data, "put");

// 新增用户
export const SaveUser = (data) =>
  HTTP(`/prod-api/business/user/add`, data, "post");

// 获得用户信息
export const GetUser = (data) =>
  HTTP(`/prod-api/business/user/${data.id}`, data, "get");

// 角色管理
export const RoleList = (data) => {
  let url = "";
  if (data?.trName) {
    url = `/prod-api/business/role/list?trName=${data.trName}&pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
  } else {
    url = `/prod-api/business/role/list?pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
  }
  return HTTP(url, data, "get");
};

// 删除角色
export const RoleDetlete = (data) =>
  HTTP(`/prod-api/business/role/${data.id}`, data, "delete");

// 更新角色
export const RoleUpdate = (data) =>
  HTTP(`/prod-api/business/role`, data, "put");

// 新增角色
export const SaveRole = (data) => HTTP(`/prod-api/business/role`, data, "post");

// 查询角色菜单
export const GetRole = (data) =>
  HTTP(`/prod-api/business/role/${data.id}`, data, "get");

// 查询菜单
export const GetRouters = (data) =>
  HTTP(`/prod-api/business/vmenu/getRouters`, data, "get");

// 查询菜单
export const GetVmenu = (data) =>
  HTTP(`/prod-api/business/vmenu/list`, data, "get");

// 关键词搜索患者
export const SearchPatient = (data) =>
  HTTP(`/prod-api/customer/public/api/v1/treated/getPatitentList`, data);

// 儿童成长学院
export const GetBusinessList = (data) =>
  HTTP(`/prod-api/business/academy/list`, data, "get");

// 一级库
export const GetLibraryList = (data) =>
  HTTP(`/prod-api/business/library/list`, data, "get");

// 二级库
export const GetLibraryCase = (data) =>
  HTTP(`/prod-api/business/options/list?caseId=${data.id}`, data, "get");

// 修改密码
export const UserModifyPwd = (data) =>
  HTTP(`/prod-api/business/userAcct/modifyPwd`, data);

// 手机号验证
export const sqlGetCode = (data) =>
  HTTP(`/prod-api/auth-business/sendVerificationCode`, data);

// 手机验证码校验
export const mobileCodeVerify = (data) =>
  HTTP(`/prod-api/auth-business/mobileLogin`, data);

// 确认密码修改
export const sqlSetPassWord = (data) =>
  HTTP(`/prod-api/business/userAcct/modifyPwdByMobile`, data);

// 诊所列表
export const reqGetOfficeList = (data) =>
  HTTP(`/prod-api/business/office/list`, data, "get");
