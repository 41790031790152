import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/main'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'redirect',
    redirect: '/check',
    hidden: true
  },
  {
    path: '/login',
    hidden: true,
    component: () => import('@/views/login')
  },
  {
    path: '/market',
    component: Main,
    name: '市场活动',
    icon: 'market',
    leaf: true,
    hidden: true,
    children: [
      {
        path: '/market/channel',
        name: '渠道管理',
        hidden: true,
        component: () => import('@/views/market/channel')
      },
      {
        path: '/market/ticket',
        name: '卡券配置',
        hidden: true,
        component: () => import('@/views/market/ticket')
      },
      {
        path: '/market/card',
        name: '卡券核销',
        hidden: true,
        component: () => import('@/views/market/card')
      }
    ]
  },
  {
    path: '/leads',
    component: Main,
    name: '患者管理',
    icon: 'leads',
    leaf: true,
    hidden: true,
    children: [
      {
        path: '/leads/audio',
        name: '录音分享',
        hidden: true,
        component: () => import('@/views/leads/audio-view.vue')
      },
      {
        path: '/leads/call-center',
        name: '通话中心',
        hidden: true,
        component: () => import('@/views/leads/call-center')
      },
      {
        path: '/leads/toll-center',
        name: '收费中心',
        hidden: true,
        component: () => import('@/views/leads/toll-center')
      },
      {
        path: '/leads/task-assign',
        name: '任务分配',
        hidden: true,
        component: () => import('@/views/leads/task-assign')
      },
      {
        path: '/leads/task-look',
        name: '任务看板',
        hidden: true,
        component: () => import('@/views/leads/task-look')
      }
    ]
  },

  {
    path: '/nkj',
    component: Main,
    name: '内窥镜',
    icon: 'nkj',
    leaf: false,
    hidden: true,
    children: [
      {
        path: '/nkj',
        name: '内窥镜',
        component: () => import('@/views/nkj/index')
      }
    ]
  },
  {
    path: '/check',
    component: Main,
    name: '口腔检查',
    icon: 'check',
    leaf: false,
    hidden: true,
    children: [
      {
        path: '/check',
        name: '口腔检查',
        component: () => import('@/views/check/index')
      }
    ]
  },
  {
    path: '/dashboard',
    component: Main,
    name: '数据看板',
    icon: 'check',
    leaf: true,
    hidden: true,
    children: [
      {
        path: '/dashboard/scoreDashboard',
        name: '拍片评分看板',
        hidden: true,
        component: () => import('@/views/scoreDashboard/index.vue')
      }
    ]
  },
  {
    path: '/odos',
    component: Main,
    name: '应治尽治',
    icon: 'treat',
    leaf: false,
    hidden: true,
    children: [
      {
        path: '/odos',
        name: '应治尽治',
        component: () => import('@/views/odos/index')
      }
    ]
  },
  {
    path: '/orth',
    component: Main,
    name: '应矫尽矫',
    icon: 'orth',
    leaf: false,
    hidden: true,
    children: [
      {
        path: '/orth',
        name: '正畸',
        component: () => import('@/views/orth/index')
      }
    ]
  },
  {
    path: '/system',
    component: Main,
    name: '系统管理',
    icon: 'setting',
    hidden: true,
    leaf: true,
    children: [
      {
        path: '/system/users',
        name: '用户管理',
        hidden: true,
        component: () => import('@/views/system/users')
      },
      {
        path: '/system/role',
        name: '角色管理',
        hidden: true,
        component: () => import('@/views/system/role')
      },
      {
        path: '/system/diagnose',
        name: '诊断字典维护',
        hidden: true,
        component: () => import('@/views/system/diagnose')
      },
      {
        path: '/system/plan',
        name: '计划字典维护',
        hidden: true,
        component: () => import('@/views/system/plan')
      },
      {
        path: '/system/inform',
        name: '公告通知',
        hidden: true,
        component: () => import('@/views/system/inform')
      }
    ]
  }
]

const router = new VueRouter({
  mode: '',
  routes
})

export default router
