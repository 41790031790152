/**
 * Created by xusheng on 2018/7/10 0010.
 */
import { getToken } from '@/hooks/cookies'
import axios from 'axios'
axios.defaults.timeout = 1200000
axios.defaults.baseURL = process.env.VUE_APP_API_URL

//http request 拦截器
axios.interceptors.request.use(
  config => {
    config.headers = {
      'Content-Type': 'application/json',
      Authorization: getToken() || ''
    }
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data'
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

//响应拦截器即异常处理
axios.interceptors.response.use(
  response => {
    return response
  },
  err => {
    let errorText = ''
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          errorText = '错误请求'
          break
        case 401:
          errorText = '未授权，请重新登录'
          break
        case 403:
          errorText = '拒绝访问'
          break
        case 404:
          errorText = '请求错误,未找到该资源'
          break
        case 405:
          errorText = '请求方法未允许'
          break
        case 408:
          errorText = '请求超时'
          break
        case 500:
          errorText = '服务器端出错'
          break
        case 501:
          errorText = '网络未实现'
          break
        case 502:
          errorText = '网络错误'
          break
        case 503:
          errorText = '服务不可用'
          break
        case 504:
          errorText = '网络超时'
          break
        case 505:
          errorText = 'http版本不支持该请求'
          break
        default:
          errorText = `连接错误${err.response.status}`
      }
    } else {
      errorText = '连接到服务器失败'
    }
    return Promise.reject(errorText)
  }
)

export async function HTTP (url = '', params = {}, method = 'post') {
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: url,
      data: params,
      timeout: 1200000
    })
      .then(({ data }) => {
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  })
}
