import { render, staticRenderFns } from "./main.vue?vue&type=template&id=a50daabc&scoped=true"
import script from "./main.vue?vue&type=script&setup=true&lang=js"
export * from "./main.vue?vue&type=script&setup=true&lang=js"
import style0 from "./main.vue?vue&type=style&index=0&id=a50daabc&prod&lang=scss&scoped=true"
import style1 from "./main.vue?vue&type=style&index=1&id=a50daabc&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a50daabc",
  null
  
)

export default component.exports